import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"background": "--color-lightD1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"align-items": "center",
			"display": "flex",
			"justify-content": "center",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline1",
			"color": "--dark",
			"sm-font": "normal 700 36px/1.2 \"Source Sans Pro\", sans-serif",
			"text-align": "center",
			"children": "Funding the Best."
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "25px 0px 0px 0px",
			"font": "--lead",
			"display": "block",
			"text-align": "center",
			"color": "--darkL2",
			"lg-width": "100%",
			"width": "60%",
			"sm-font": "normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"children": "Donera is proud to support the top nonprofits and institutions combating climate change. We research and vet every organization so you don't have to."
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"margin": "40px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "calc(100% / 6)",
			"md-width": "calc(100% / 3)",
			"justify-content": "center",
			"padding": "0px 0px 0px 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/62958497fedca4001e327b15/images/rainforest%20action%20network%20logo.png?v=2022-06-03T07:16:33.635Z",
			"border-radius": "16px",
			"max-width": "100%",
			"padding": "0px 030px 0px 30px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "calc(100% / 6)",
			"md-width": "calc(100% / 3)",
			"justify-content": "center",
			"padding": "0px 0px 0px 0px"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/62958497fedca4001e327b15/images/350.org%20logo.png?v=2022-06-03T07:16:59.783Z",
			"border-radius": "16px",
			"max-width": "100%",
			"display": "inline-block",
			"padding": "0px 30px 0px 30px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "calc(100% / 6)",
			"md-width": "calc(100% / 3)",
			"justify-content": "center",
			"padding": "0px 0px 0px 0px"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/62958497fedca4001e327b15/images/climate%20emergency%20fund%20logo.png?v=2022-06-03T07:15:48.743Z",
			"border-radius": "16px",
			"max-width": "100%",
			"padding": "0px 30px 0px 30px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "calc(100% / 6)",
			"md-width": "calc(100% / 3)",
			"justify-content": "center"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/62958497fedca4001e327b15/images/coral%20reef%20alliance.png?v=2022-06-03T07:16:09.796Z",
			"border-radius": "16px",
			"max-width": "100%",
			"padding": "0px 30px 0px 30px"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "calc(100% / 6)",
			"md-width": "calc(100% / 3)",
			"justify-content": "center"
		}
	},
	"image4": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/62958497fedca4001e327b15/images/ITIF%20logo.webp?v=2022-06-03T05:24:34.066Z",
			"border-radius": "16px",
			"max-width": "100%",
			"padding": "0px 30px 0px 30px"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "calc(100% / 6)",
			"md-width": "calc(100% / 3)",
			"justify-content": "center"
		}
	},
	"image5": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/62958497fedca4001e327b15/images/Acadia%20Center.webp?v=2022-06-03T05:24:33.951Z",
			"border-radius": "16px",
			"max-width": "100%",
			"padding": "0px 30px 0px 30px"
		}
	}
};

const Funding = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Image {...override("image")} />
			</Box>
			<Box {...override("box3")}>
				<Image {...override("image1")} />
			</Box>
			<Box {...override("box4")}>
				<Image {...override("image2")} />
			</Box>
			<Box {...override("box5")}>
				<Image {...override("image3")} />
			</Box>
			<Box {...override("box6")}>
				<Image {...override("image4")} />
			</Box>
			<Box {...override("box7")}>
				<Image {...override("image5")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Funding, { ...Section,
	defaultProps,
	overrides
});
export default Funding;