import React from "react";
import { useOverrides, Override, Formspree } from "@quarkly/components";
import { Box, Icon, Text, Link, LinkBox, Input, Button, Structure, Section } from "@quarkly/widgets";
import { MdEmail, MdPinDrop } from "react-icons/md";
import { FaLinkedinIn, FaInstagram } from "react-icons/fa";
const defaultProps = {
	"background-color": "--dark",
	"text-align": "center",
	"padding": "32px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"sm-flex-direction": "column",
			"sm-width": "100%",
			"sm-text-align": "center",
			"justify-content": "flex-start",
			"align-items": "center"
		}
	},
	"structure": {
		"kind": "Structure",
		"props": {
			"cells-number-total": "2",
			"cells-number-group": "2"
		}
	},
	"structureOverride": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"sm-grid-template-columns": "12fr",
			"grid-template-columns": "2fr 1fr"
		}
	},
	"overrideOverride": {
		"kind": "Override",
		"props": {
			"slot": "cell-1"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"sm-padding": "0 0 0 0",
			"margin": "64px 0 0 0",
			"max-width": "360px",
			"position": "relative",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"sm-width": "100%",
			"sm-max-width": "100%",
			"sm-margin": "0 0 0 0"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"position": "absolute",
			"size": "48px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdEmail,
			"color": "--light",
			"sm-display": "none"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "--base",
			"sm-width": "100%",
			"color": "--light",
			"text-align": "left",
			"children": "Email us"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "normal 500 24px/1.2 --fontFamily-sans",
			"sm-text-align": "left",
			"children": <Link
				href="mailto:givedonera@gmail.com"
				text-decoration="none"
				hover-text-decoration="underline"
				color="--light"
				sm-width="100%"
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				givedonera@gmail.com
			</Link>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"color": "--light",
			"sm-padding": "9 0px 0px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"margin": "64px 0 0 0",
			"max-width": "360px",
			"position": "relative",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"sm-width": "100%",
			"sm-max-width": "100%",
			"sm-padding": "0 0 0 0",
			"sm-margin": "0 0 0 0"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"position": "absolute",
			"size": "48px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdPinDrop,
			"sm-display": "none"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "--base",
			"sm-width": "100%",
			"text-align": "left",
			"children": "Address"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "normal 500 24px/1.2 --fontFamily-sans",
			"sm-width": "100%",
			"text-align": "left",
			"children": "Chapel Hill, NC"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"sm-padding": "0",
			"margin": "48px 0",
			"max-width": "360px",
			"position": "relative",
			"display": "flex",
			"text-align": "center",
			"justify-content": "flex-end",
			"sm-margin": "12px 0 12px 0"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.linkedin.com/company/givedonera/",
			"target": "_blank"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaLinkedinIn,
			"width": "48px",
			"height": "48px",
			"size": "24px",
			"margin-right": "16px",
			"background": "--color-light",
			"border-radius": "50%"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.instagram.com/give_donera/",
			"target": "_blank"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaInstagram,
			"width": "48px",
			"height": "48px",
			"size": "24px",
			"margin-right": "16px",
			"background": "--color-light",
			"border-radius": "50%"
		}
	},
	"overrideOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Cell 0th"
		}
	},
	"overrideOverride2": {
		"kind": "Override",
		"props": {
			"slot": "cell-0"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "56px 48px",
			"margin": "0 0 0 0",
			"md-max-width": "100%",
			"sm-padding": "24px 12px 36px 12px",
			"max-width": "95%",
			"border-color": "--color-light",
			"border-width": "2px",
			"border-style": "solid",
			"height": "420px"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "0 0 20px 0",
			"sm-padding": "0px 8px 0px 8px",
			"sm-margin": "0 0 8px 0",
			"color": "--light",
			"text-align": "left",
			"children": "Drop us a Line."
		}
	},
	"formspree": {
		"kind": "Formspree",
		"props": {
			"endpoint": "https://formspree.io/f/xjvldowr",
			"color": "--light"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"gap": "16px",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"width": "100%",
			"name": "name",
			"type": "text",
			"placeholder": "Name"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"width": "100%",
			"type": "email",
			"name": "email",
			"placeholder": "Email"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"as": "textarea",
			"rows": "4",
			"width": "100%",
			"name": "message",
			"placeholder": "Message",
			"height": "100px"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-end"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"background": "--color-dark",
			"color": "--light",
			"border-color": "--color-light",
			"border-width": "2px",
			"border-style": "solid"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--grey",
			"display": "block",
			"children": "© Donera. Built with love and tons of tea."
		}
	}
};

const DoneraFooter = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")} />
		<Structure {...override("structure")}>
			<Override {...override("structureOverride")}>
				<Override {...override("overrideOverride")}>
					<Box {...override("box1")}>
						<Icon {...override("icon")} />
						<Text {...override("text")} />
						<Text {...override("text1")} />
					</Box>
					<Box {...override("box2")}>
						<Box {...override("box3")}>
							<Icon {...override("icon1")} />
							<Text {...override("text2")} />
							<Text {...override("text3")} />
						</Box>
					</Box>
					<Box {...override("box4")}>
						<LinkBox {...override("linkBox")}>
							<Icon {...override("icon2")} />
						</LinkBox>
						<LinkBox {...override("linkBox1")}>
							<Icon {...override("icon3")} />
						</LinkBox>
					</Box>
				</Override>
				<Override {...override("overrideOverride1")} />
				<Override {...override("overrideOverride2")}>
					<Box {...override("box5")}>
						<Text {...override("text4")} />
						<Formspree {...override("formspree")}>
							<Box {...override("box6")}>
								<Box {...override("box7")}>
									<Input {...override("input")} />
								</Box>
								<Box {...override("box8")}>
									<Input {...override("input1")} />
								</Box>
								<Box {...override("box9")}>
									<Input {...override("input2")} />
								</Box>
								<Box {...override("box10")}>
									<Button {...override("button")}>
										Send
									</Button>
								</Box>
							</Box>
						</Formspree>
					</Box>
				</Override>
			</Override>
		</Structure>
		<Text {...override("text5")} />
		{children}
	</Section>;
};

Object.assign(DoneraFooter, { ...Section,
	defaultProps,
	overrides
});
export default DoneraFooter;